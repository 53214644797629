/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-magic-numbers */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { createRef } from 'react';

import { SliderComponent as SourceSlider } from 'SourceComponent/Slider/Slider.component';
import { ReactComponent as ArrowLeft } from 'Style/icons/arrow-left-slider.svg';
import { ReactComponent as ArrowRight } from 'Style/icons/arrow-right-slider.svg';
import { noopFn } from 'Util/Common';
import CSS from 'Util/CSS';

import {
    ANIMATION_DURATION,
} from './Slider.config';

import './Slider.style';

/**
 * Slider component
 * @class Slider
 * @namespace Satisfly/Component/Slider/Component */
export class SliderComponent extends SourceSlider {
    static defaultProps = {
        activeImage: 0,
        onActiveImageChange: noopFn,
        showCrumbs: false,
        showArrows: true,
        showCounter: false,
        isInteractionDisabled: false,
        mix: {},
        onClick: null,
        isVertical: false,
        isHeightTransitionDisabledOnMount: false,
        sliderHeight: null,
        sliderRef: null,
    };

    counterRef = createRef();

    __construct(props) {
        super.__construct(props);

        const { activeImage } = this.props;

        this.state = {
            prevActiveImage: activeImage,
            translateX: 0,
        };
    }

    setAnimationSpeedStyle(animationDuration = ANIMATION_DURATION) {
        CSS.setVariable(this.draggableRef, 'animation-speed', `${ animationDuration }ms`);
        CSS.setVariable(this.counterRef, 'animation-speed', `${ animationDuration }ms`);
    }

    renderCounter() {
        const { children, showCounter, activeImage } = this.props;
        const { translateX } = this.state;
        const totalTrackWidth = Math.abs(this.getSlideWidth() * children.length);

        if (!showCounter || children.length <= 1) {
            return null;
        }

        const scrollbarCoordinates = {
            width: `${100 / children.length}%`,
            left: `${((Math.abs(translateX) / totalTrackWidth) * 100)}%`,
        };

        return (
            <div
              block="Slider"
              elem="Counter"
              ref={ this.counterRef }
            >
                <div block="Counter" elem="Scrolltrack" onClick={ this.handleCounterScrolltrackClick.bind(this) }>
                    <div block="Counter" elem="Scrollbar" style={ scrollbarCoordinates } />
                </div>
                <div block="Counter" elem="Value">
                    { __('%s of %s', activeImage + 1, children.length) }
                </div>
            </div>
        );
    }

    handleCounterScrolltrackClick(e) {
        const { children } = this.props;

        const offsets = e.target.getBoundingClientRect();
        const trackWidth = (offsets.right + window.scrollX) - (offsets.left + window.scrollX);
        const clickPosition = e.clientX - (offsets.left + window.scrollX);
        const slideWidth = trackWidth / children.length;
        const imageIndex = Math.floor(clickPosition / slideWidth);

        this.changeActiveImage(imageIndex);
    }

    setTranlateXStyle(translate) {
        const { isVertical } = this.props;

        this.setState({ translateX: translate });

        CSS.setVariable(
            this.draggableRef,
            isVertical ? 'translateY' : 'translateX',
            `${ translate }px`
        );
    }

    renderArrows() {
        const {
            showArrows, activeImage, children,
        } = this.props;
        const nextIsDisabled = activeImage + 1 === children.length;
        const prevIsDisabled = activeImage === 0;

        if (!showArrows || children?.length <= 1) {
            return null;
        }

        return (
            <>
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isPrev: true, isDisabled: prevIsDisabled } }
                  aria-label={ __('Previous') }
                  onClick={ this.goPrev }
                >
                    <ArrowLeft />
                </button>
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isNext: true, isDisabled: nextIsDisabled } }
                  aria-label={ __('Next') }
                  onClick={ this.goNext }
                >
                    <ArrowRight />
                </button>
            </>
        );
    }

    render() {
        const { mix } = this.props;

        return (
            <>
                <div
                  block="Slider"
                  mix={ mix }
                  ref={ this.getSliderRef() }
                >
                    { this.renderSliderContent() }
                    { this.renderCrumbs() }
                </div>
                { this.renderCounter() }
                { this.renderArrows() }
            </>
        );
    }
}

export default SliderComponent;
